<template>
  <div>
    <b-row>
      <b-col>
        <export-button :filter="{userId : $route.params.id}" 
                       url="/export-blokuser" 
                       :disabled="false" 
                       file-title="Blocking reasons.xlsx"
        />
      </b-col>
      <b-col md="12">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal id="modalResponse" title="PopUp Response" cancel-variant="outline-secondary" scrollable>
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>
          <!-- search -->
          <br>
          <b-col md="3" class="d-flex align-items-center justify-content-center mb-1">
            <b-form-input v-model="search" class="d-inline-block mr-1" :placeholder="$t('user-list.text.filter')" @input="showBlokUser" />
          </b-col>
          <!-- table -->
          <b-table md="9" :items="dataItems" :fields="fields" class="scroll" responsive>
            <template #cell(createdAt)="data">
              {{ data.item.createdAt | moment }}
            </template>
            <template #cell(type)="data">
              <div v-if="data.item.type === false" class="full-width"
                style="background-color: #f3f2f7;color: #6e6b7b;width: 45%;max-width: 150px;display: flex;justify-content: space-between;border-radius: 10px;padding: 4px 8px; align-items: center;d-color:gray;">
                <span> {{$t('labels.locked')}} </span>
                <feather-icon :icon="data.item.type ? 'UnlockIcon' : 'LockIcon'  " />
              </div>
              <div v-else class="full-width"
                style="background-color: #efb810;color: #ffff;width: 45%;max-width: 150px;display: flex;justify-content: space-between;border-radius: 10px;padding: 4px 8px; align-items: center;d-color:gray;">
                <span> {{$t('labels.unlocked')}}</span>
                <feather-icon :icon="data.item.type ? 'UnlockIcon' : 'LockIcon'  " />
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">{{ $t('labels.showing') }} {{ from }} {{$t('labels.to')}} {{ to }} {{ $t('labels.of') }} {{totalRows}} {{ $t('labels.entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                <b-pagination v-model="serverParams.page" :total-rows="totalRows" :per-page="serverParams.limit"
                  first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
                  :simple="true" :hide-goto-end-buttons="true">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import axios from '@axios';
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import ExportButton from "@/views/pages/export/ExportButton.vue";

export default {
  components: {
    ExportButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    HierarchyUsers
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      from : 0,
      to : 0,
      loadingData: false,
      dataItems: [],
      dataIdUser: [],
      pages: [20, 40, 60],
      search: "",
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      filterSelect: {
        ticketId: "",
        status: "",
        type: "",
        dateFrom: moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      searchId: "",

    };
  },
  // add filters
  filters: {
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },

  watch: {
    serverParams: {
      handler: function handler(newObject,oldObject) {
        if (newObject.page !== oldObject.page)
          this.showBlokUser()
      },
      deep: true,
    }
  },

  methods: {
    /*List - Index 123*/
    async showBlokUser() {
      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL

        const params = {
          userId: this.$route.params.id,
          page : this.serverParams.page,
          q : this.search
        }

        const result = axios
          .post(`${VUE_APP_URL}/showBlokUser`, params)
          .then(r => {
              const localItemsCount = r.data.block ? r.data.block.length : 0
              this.dataItems = r.data.block
              this.totalRows = r.data.total
              this.serverParams.limit = r.data.perPage
              this.serverParams.page = r.data.page
              this.from = r.data.perPage * (r.data.page -1) + (localItemsCount ? 1 : 0)
              this.to = r.data.perPage * (r.data.page - 1) + localItemsCount
          })
          .catch(error => console.log('Error Encontrado:', error.response))

        return result

      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async selectUserHierarchy(id) {
      const currency = this.$store.state.whitelabelCurrencyNabvar.currency
      const whitelabel = 248
      const VUE_APP_URL = process.env.VUE_APP_URL
      const params = {
        "id": id,
        "currency": currency,
        "whitelabel": whitelabel
      }
      let result = await axios
        .post(`${VUE_APP_URL}/treeUsers`, params)
        .then(r => r.data == "" ? 0 : r.data)
        .catch(error => console.log(error.response))
      return result

    },
    onPageChange(page) {
      console.log(page, "page");
      this.serverParams.page = page;
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.limit = limit;
    },
    inputSelect() {
      let id = this.dataIdUser
    },
  },
  mounted() {
    this.showBlokUser()
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
  computed: {
    fields() {
      return [
        { label: this.$t('message.tableHeader.date'), key: "createdAt" },
        { label: this.$t('user-list.text.comment'), key: "comment" },
        { label: this.$t('message.tableHeader.action'), key: "type" },
      ]
    }
  }
};
</script>

<style lang="scss" >
.per-page-selector {
  width: 90px;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

