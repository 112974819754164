<template>
 <div>
   <b-row>
     <b-col md="12">
        <b-card
          no-body
          class="p-1"
        >
            <b-row>
              <b-col md="3"> 
                <b-form-group
                  label="Date from"
                  label-for="datefrom"
                  rules="required"
                >
                <template #label>{{$t('labels.datefrom')}}</template>
                <flat-pickr
                    v-model="dateFrom"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'd-m-Y - H:i' ,
                      enableTime: true,
                      dateFormat: 'Y-m-d:H:i',
                      time_24hr: true,
                      wrap : true,
                      plugins: [
                         new yearDropdownPlugin({
                           yearStart: 100,
                           yearEnd: 0
                        })
                       ],
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Date To"
                  label-for="dateto"
                  rules="required"
                >
                <template #label>{{$t('labels.dateto')}}</template>
                <flat-pickr
                    v-model="dateTo"
                    class="form-control"
                    :config="{altInput: true, altFormat: 'd-m-Y - H:i' , enableTime: true, dateFormat: 'Y-m-d:H:i',  time_24hr: true}" 
                  />
                </b-form-group>
              </b-col>
                <b-col md="3" >
                    <b-form-group
                          :label="$t('labels.search_historic')"
                            label-for="isHistorical"
                    >
                        <template #label>{{ $t('user-list.text.historic') }}</template>
                        <b-form-checkbox
                                id="isHistorical"
                                v-model="isHistorical"
                                @change="getWalletsTransactions"
                        />
                    </b-form-group>
                </b-col>
              <b-col md="3">
                  <b-form-group
                    label="Search Username"
                    label-for="username"
                  >
                    <template #label>{{$t('labels.search')}}</template>
                    <b-form-input
                      id="username"
                      v-model="q"
                    />
                  </b-form-group>
              </b-col>
            </b-row>
            <br>
            <b-overlay :show="loadindData" rounded responsive variant="transparent" opacity="0.33" blur="2px">
                <div>
                  <b-table 
                    small
                    responsive
                    :items="items"
                    :fields="fields"
                    :search-options="{
                      enabled: true,
                    }"                    
                  >
                    <template #cell(date)="data">
                      <span class="text-nowrap">{{data.item.createdAt | moment  }}</span>
                    </template>
                     
                    <template #cell(transaction_id)="data">
                        <span>
                          {{data.item._id }}
                        </span>
                    </template>
                    
                    <template #cell(supplier_reference)="data">
                        <span class="d-flex justify-content-center">
                          {{data.item.reference || '-'}}
                        </span>
                    </template>

                    <template #cell(productId)="data">
                      <span class="d-flex justify-content-center">
                        {{data.item.productId || '-'}}
                      </span>
                    </template>

                    <template #cell(gameName)="data">
                      <span class="d-flex justify-content-center">
                        {{data.item.gameId || '-'}}
                      </span>
                    </template>

                    <template #cell(brandName)="data">
                      <span class="d-flex justify-content-center">
                        {{data.item.brandId || '-'}}
                      </span>
                    </template>

                    <template #cell(description)="data">
                      <span>{{data.item.description}}</span>
                        <span v-if="data.item.operation === 'CREDIT' || data.item.operation === 'WIN'">
                            {{data.item.platformName}}
                        </span>
                    </template>
                    <template #cell(amount)="data">
                      <span :class="data.item.operation === 'CREDIT' || data.item.operation === 'WIN' ? 'text-success':'text-danger'"
                            class="text-nowrap d-flex justify-content-center">
                       {{data.item.operation === 'CREDIT' || data.item.operation === 'WIN' ? '':'-'}}{{data.item.amount | currency({symbol: currencySelect})}}
                      </span>
                    </template>                    
                    <template #cell(balance_before)="data">
                      <span class="text-nowrap">{{data.item.balance_before | currency({symbol: currencySelect})}}</span>
                    </template>
                                   
                    <template #cell(balance_after)="data">
                      <span class="text-nowrap font-bold">{{data.item.balance_after | currency({symbol: currencySelect})}}</span>
                    </template>

                    <template #cell(status)="data">
                      <span class="text-nowrap font-bold d-flex justify-content-center">{{data.item.isPayment ? data.item.transaction_status : "-"}}</span>
                    </template>
                    <template #cell(operation)="data">
                      <div v-if="data.item.isPayment">
                         <span v-if="data.item.operation === 'BET'">
                            {{$t('debit')}}
                        </span>
                        <span v-else>
                           {{$t('credit')}}
                        </span>
                      </div>
                      <div v-else>
                       {{data.item.operation}}
                      </div>
                    </template>

                  </b-table>
                  <div v-if="items.length > 0" class="w-full d-flex justify-content-between">
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="limitOptions"
                      @input="getWalletsTransactions"
                      :clearable="false"
                    />
                  <div>
                    <b-pagination
                    v-model="page"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    :simple="true"
                    :hide-goto-end-buttons="true"
                  ></b-pagination>
                  </div>
                </div>
                  <div class="d-flex justify-content-center mt-1"  v-if="!items.length > 0" >
                      <h3>{{ $t('no_data') }}</h3>
                  </div>
              </div>
            </b-overlay>

        </b-card>
     </b-col>

   </b-row>
 </div>
</template>


<script>

import {
  BCard, BCardHeader, BCardBody, BCollapse, BForm, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BButton, BAlert, BFormCheckbox, BCardText, BOverlay, BCardTitle
} from 'bootstrap-vue'
import { BTable } from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted} from '@vue/composition-api'
import 'vue-tree-halower/dist/halower-tree.min.css'
import { VTree, VSelectTree } from 'vue-tree-halower'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import HierarchyUsers from '@core/components/HierarchyUsers/HierarchyUsers.vue'
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    VSelectTree,
    vSelect,
    VTree,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    flatPickr,
    HierarchyUsers
  },
  props: {
    userId: {
      type: String,
      required: true
    }
},
  data() {
    return {
      items: [],
      dateFrom: moment().format('YYYY-MM-DD:00:00:00'), //2022-05-01
      dateTo: moment().format('YYYY-MM-DD:23:59:59'), //2022-05-16
      TransactionsWallet: "",
      page: 1,
      sortDesc: false,
      q: "",
      loadindData: false,
      timeOut: null,
      perPage: 10,
      limitOptions: [10, 20, 50, 100, 200, 500],
      totalRows: 0,
      isHistorical : false
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    }
  },
  computed: {
      currencySelect(){
      return  this.$store.state.whitelabelCurrencyNabvar.currency
    },
    fields() {
      return [
        {key: "date", label: this.$t('labels.date') },
        {key: "ip", label: 'IP'},
        {key: "transaction_id", label: this.$t('labels.transaction_id')},
        {key: "supplier_reference", label: this.$t('labels.supplier_reference')},
        {key: "productId", label: this.$t('labels.operator') },
        {key: "gameName", label: this.$t('game') },
        {key: "brandName", label: this.$t('brand') },
        {key: "description", label: this.$t('labels.description') },
        {key: "operation", label: this.$t('labels.operation')},
        {key: "amount", label: this.$t('labels.import') },
        {key: "balance_before", label: this.$t('labels.previousbalance') },
        {key: "balance_after", label: this.$t('labels.nextbalance')},
        {key: "status", label: this.$t('labels.status')}
      ]
    }
  },
  watch: {
    currencySelect(){
      this.getWalletsTransactions()
    },
    dateFrom(){
      this.searchLazy()
    },
    dateTo(){
      this.searchLazy()
    },
    q(){
      this.searchLazy()
    },  
    perPage(){
      this.page = 1
      this.searchLazy()
    },
    page(){
      this.getWalletsTransactions()
    }
  },
  methods: {
    yearDropdownPlugin,
    //jeanrosa metodo que activa el fetchsTransactions en el clientStoredModule.js
    async getWalletsTransactions(ctx, callback){
      try{
        this.loadindData = true
        this.TransactionsWallet = await store.dispatch('user/fetchsTransactions', {
          perPage: this.perPage, //numero de registro por pagina 
          page: this.page, //pagina actual
          sortDesc: this.sortDesc,
          q: this.q,
          id: this.userId, //Usuario actual conectado
          currency: this.currencySelect,// tomar del currency que esté en el formulario
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          isHistorical : this.isHistorical
        })
        this.items = this.TransactionsWallet.data.data
        this.totalRows = this.TransactionsWallet.data.total
      }catch(error){
        console.error(error);
      }finally{
        this.loadindData = false
      }
    },
    searchLazy(){
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.getWalletsTransactions()
      }, 600)
    },
    
  },
  created () {
    this.getWalletsTransactions()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.tree-block{
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}

.selected{
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border:1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}

[dir] .table th, [dir] .table td {
    padding: 0.72rem 0.7rem;
}
</style>