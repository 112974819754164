<template>
    <b-card no-body class="border-primary">
        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
            <h5 class="mb-0">
                {{ $t('user-list.text.wallet') }}
            </h5>
            <b-badge variant="light-primary">
                <!-- {{ "Prepago" }} -->
            </b-badge>
        </b-card-header>

        <b-card-body >
            <ul class="list-unstyled">
                <li>
                    <span class="align-middle text-nowrap">{{ $t('labels.balance') }}:

                        <b-dropdown
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            size="sm"
                            :text="currencySelect.balance | currency({ symbol: currencySelect.currency})"
                            variant="outline-success"
                        >
                            <b-dropdown-item
                                v-for="(currency, index) in currencysArr"
                                :key="index"
                                @click="changeCurrencySelect(currency)"
                            >
                                {{ currency.balance | currency({ symbol: currency.currency}) }}
                            </b-dropdown-item>
                        </b-dropdown>
                       </span>
                </li>
                <li class="my-25">
                    <span class="align-middle text-nowrap">{{ $t('user-list.text.lastRecharge') }}: <span class="text-success">10 $</span> </span>
                </li>
                <li class="my-25">
                    <b-row>
                        <b-col class="col-auto pr-0 align-middle text-nowrap">{{ $t('labels.currencies') }}: </b-col>
                        <b-col class="text-success">{{currencysList}}</b-col>
                    </b-row>
                </li>
            </ul>
            <div class="d-flex justify-content-center align-items-center mb-1">
                <!-- <vue-qr :text="walletData.public_key" :size="130" :margin="0" :logoCornerRadius="10"
                    :bindElement="true"></vue-qr> -->
            </div>

<!--            <div class="d-flex justify-content-end align-items-center">-->
<!--                &lt;!&ndash; <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm">-->
<!--                    Transacciones-->
<!--                </b-button> &ndash;&gt;-->
<!--                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm">-->
<!--                    Recargar/Retirar-->
<!--                </b-button>-->
<!--            </div>-->
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard, BCardHeader, BCardBody, BBadge, BButton,
    BDropdown, BDropdownItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ref, computed, onMounted, onUnmounted} from "@vue/composition-api";
// import VueQr from 'vue-qr'
import store from '@/store'
import { $themeConfig } from "@themeConfig";


export default {
    directives: {
        Ripple,
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
    },
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BBadge,
        BButton,
        BDropdown, BDropdownItem

        // VueQr,
    },
    setup(props) {
        // const walletData = ref(props.userData.wallet);
        const { appName, appLogoImage } = $themeConfig.app;

        let currencySelect = ref(props.userData.wallet.currencies[0]);
        const currencysList = props.userData.wallet.currencies.map(currency => currency.currency).join(', ');
        const currencysArr = ref(props.userData.wallet.currencies);

        const changeCurrencySelect = (currency)=>{
            currencySelect.value = currency
        }


        // computed
        // const balanceCurrencyDefault = computed(() => {
        //     return props.userData.wallet.balance_currencies.find(currency => currency.default);
        // });
        return {
            // walletData,
            appLogoImage,
            currencysList,
            currencySelect,
            currencysArr,
            changeCurrencySelect
        }
    }

}
</script>

<style>
</style>
