<template>
    <div>
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="userData == undefined && !userDataLoading">
            <h4 class="alert-heading">
                {{ $t('user-list.messages.errorLoadData') }}
            </h4>
            <div class="alert-body">
                {{ $t('user-list.text.userNotFound') }}
                <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
                    {{ $t('user-list.text.userList') }}
                </b-link>
                {{ $t('user-list.text.forOthersUsers') }}
            </div>
        </b-alert>

        <b-overlay :show="userDataLoading" rounded responsive variant="transparent" opacity="0.33" blur="2px">
            <template v-if="userData">
                <!-- First Row -->
                <b-row>
                    <b-col cols="12" xl="8" lg="8" md="7">
                        <user-view-user-info-card :user-data="userData" />
                    </b-col>
                    <b-col cols="12" xl="4" lg="4" md="5">
                        <user-view-user-wallet-info :user-data="userData"/>
                    </b-col>
                    <b-col cols="12" md="12">
                        <b-card>
                            <b-tabs >
                                <b-tab :title="$t('tabs.userhistory')">
                                    <user-view-user-history v-if="userData" :userId="userData._id"/>
                                </b-tab>
                                <b-tab :title="$t('user-list.text.bitacora')">
                                    <user-view-user-bitacora v-if="userData" :userId="userData._id"/>
                                </b-tab>
                                <b-tab :title="$t('user-list.text.motivoBloqueo')">
                                    <user-view-user-blok v-if="userData" :userId="userData._id"/>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </b-col>
                </b-row>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BAlert,
    BTabs, 
    BTab,
} from "bootstrap-vue";
import { formatDateTimeHumane } from "@core/utils/filter.js";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import { ref, computed, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";

// component for user info card
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
// user-view-user-wallet-info
import UserViewUserWalletInfo from './UserViewUserWalletInfo.vue'
import UserViewUserHistory from './UserViewUserHistory.vue'
import UserViewUserBitacora from './UserViewUserBitacora.vue'
import UserViewUserBlok from './UserViewUserBlok.vue'
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BOverlay,
        BAlert,
        BTabs, 
        BTab,

        // Librery components
        vSelect,

        // Custom components
        UserViewUserInfoCard,
        UserViewUserWalletInfo,
        UserViewUserHistory,
        UserViewUserBitacora,
        UserViewUserBlok,
    },
    filters: {
        formatDateTimeHumane,
        avatarText,
    },
    setup(props, context) {
        // VARIABLES
        const idUser = router.currentRoute.params.id;
        const userData = ref(null);
        const userDataLoading = ref(true);

        // COMPUTED

        // WATCHERS

        // METHODS
        const getUser = async () => {
            try {
                userDataLoading.value = true;
                const data = await store.dispatch("user/showUser", idUser);
                userData.value = data;
            } catch (e) {
                console.log(e);
            } finally {
                userDataLoading.value = false;
            }
        };

        //  LIFECYCLE HOOKS
        onMounted(() => {
            getUser();
        });

        // RETURN
        return {
            userData,
            userDataLoading,
        };
    },
};
</script>

<style>
</style>