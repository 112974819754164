<template>
  <b-card>
    <UserListAddNewcopy
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActivecopy"
      @refetch-data="refreshUserMethod()"
      ref="UserListAddNewcopy"
    />

    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="avatar-user-info">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.userName)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ `${userData.userName}` }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>

              <section
                v-if="userData._id"
                class="d-flex flex-column justify-center rounded"
                style="margin-top: 5px"
              >
                <span class="font-weight-bold font-small-2"
                  >{{ $t('labels.user_id') }}:</span
                >
                <span class="card-text">{{ userData._id }}</span>
              </section>
            </div>
            <div class="d-flex flex-wrap">
              <b-button @click="EditUser()" variant="primary" size="sm">
                {{$t('tooltips.edit')}}
              </b-button>
              <!--              <b-button-->
              <!--                variant="outline-danger"-->
              <!--                class="ml-1"-->
              <!--                size="sm"-->
              <!--              >-->
              <!--                Eliminar-->
              <!--              </b-button>-->
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">23.3k</h5>
              <small>{{ $t('bets') }}</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">$99.87k</h5>
              <small>{{ $t('user-list.text.profits') }}</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100 table-user-info">
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('labels.typeuser') }}</span>
            </th>
            <td class="pb-50 text-nowrap">
              {{ `${userData.typeUser}` }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 text-nowrap">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span
                class="font-weight-bold"
                >{{ $t('labels.affiliate-code') }}</span
              >
            </th>
            <td v-if="userData.affiliateCode" class="pb-50">
              {{ `${userData.affiliateCode}` }}
            </td>
            <td class="pb-50 text-nowrap">
              {{ `-` }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span
                class="font-weight-bold"
                >{{ $t('labels.userFather') }}</span
              >
            </th>
            <td class="pb-50 text-nowrap">
              {{ parentUser }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span
                class="font-weight-bold"
                >{{ $t('message.tableHeader.status') }}</span
              >
            </th>
            <td class="pb-50 text-nowrap">
              {{ userData.status ? 'Activo' : 'Inactivo' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('labels.role') }}</span>
            </th>
            <td class="pb-50 text-nowrap">
              {{ userData.roles.length > 0 ? userData.roles[0].name : 'No tiene roles' }}
            </td>
          </tr>
          <tr v-if="userData.email">
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ `${userData.email}` }}
            </td>
          </tr>
          <tr v-if="userData.createdAt">
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('created_at')}}</span>
            </th>
            <td class="pb-50">
              {{ userData.createdAt | moment }}
            </td>
          </tr>
          <tr v-if="userData.updatePassword">
            <th class="pb-50">
              <feather-icon icon="LockIcon" class="mr-75" />
              <span
                class="font-weight-bold"
                >{{$t('user-list.messages.passwordUpdated')}}</span
              >
            </th>
            <td class="pb-50">
              {{ userData.updatePassword | moment }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span
                class="font-weight-bold"
                >{{$t('labels.is_valid_document_yes')}}</span
              >
            </th>
            <td v-if="userData.documents.acceptanceDate" class="pb-50">
              {{ userData.documents.acceptanceDate | moment }}
            </td>
            <td v-else="userData.documents.acceptanceDate" class="pb-50">-</td>
          </tr>
          <tr v-if="userData.address">
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('fields.Address') }}</span>
            </th>
            <td class="pb-50">
              {{ `${userData.address}` }}
            </td>
          </tr>
          <tr v-if="userData.phone">
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('fields.Phone') }}</span>
            </th>
            <td class="pb-50">
              {{ `${userData.phone}` }}
            </td>
          </tr>
          <tr v-if="userData.country">
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('fields.Country') }}</span>
            </th>
            <td class="pb-50">
              {{ `${userData.country}` }}
            </td>
          </tr>
          <tr v-if="whitelabelList">
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Whitelabels</span>
            </th>
            <td class="pb-50">
              {{ `${whitelabelList}` }}
            </td>
          </tr>
          <tr v-if="productsList">
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('labels.products') }}</span>
            </th>
            <td class="pb-50">
              {{ `${productsList}` }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import UserListAddNewcopy from '../users-list/AddUser/UserListAddNewcopy'
import {computed, ref} from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import moment from "moment"

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, UserListAddNewcopy,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  filters: {
    avatarText,
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },
  setup(props) {

    const whitelabelList = props.userData.whitelabels.map(whitelabel => {
      return `${whitelabel.name}`
    }).join(', ')

    const productsList = props.userData.products.map(product => {
      return `${product.name}`
    }).join(', ')

    const isAddNewUserSidebarActivecopy = ref(false)

    const {
      fetchUsers,
    } = useUsersList()

    const parentUser = computed(() => {
        console.log(props.userData)
      if (!props.userData) {
        return 'N/A'
      }

      if (!props.userData.branch) {
        return 'N/A'
      }

      return props.userData.branch.userName || 'N/A'
  })

    return {
      avatarText,
      whitelabelList,
      productsList,
      isAddNewUserSidebarActivecopy,
      fetchUsers,
      parentUser
    }
  },
  methods:{
    EditUser() {
      this.$refs.UserListAddNewcopy.methoEditUser(this.userData)
      this.isAddNewUserSidebarActivecopy = true
    },
    refreshUserMethod() {
      this.$router.push({ name: 'apps-users-list'})
    },
  }
}
</script>

<style lang="scss" scoped>
.avatar-user-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.table-user-info {
  @media (max-width: 768px) {
    tr{
      display: flex;
      flex-direction: column;
      padding: 10px 0;
    }
  }
}
</style>
